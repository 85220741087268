<template>
  <div class="text-sm">
     <br>
     <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Report List Produk/Barang</p>
     <hr class="mt-1 mb-1">
     <br>
     <br>
      <div class="row justify-content-center">
        <div class="col-12 mb-4 bg-white rounded-lg shadow shadow p-3">
            <p class="text-center p-3">Cari data Stok Barang Masuk</p>
            <div class="row justify-content-center">
              <!--  -->
              <div class="col-6 font-semibold">
                Type : 
                <select class='form-control d-inline' v-model="type" style="width:200px;">
                <option value="0">--All Type--</option>
                <option value="1">Di Jual</option>
                <option value="2">Tidak Di Jual</option>
                </select>
              </div>
              <div class="col-6"></div>
              <div class="col-12">
                <hr>
              </div>
              <!--  -->
              <div class="col-6 text-center">
                    <div class="sm-form ">
                        <input type="date" id="tanggal1" name="tanggal1" class="form-control form-control-sm" placeholder="tanggal1" v-model="vdata.tanggal1" >
                    </div>
                    S/D
                    <div class="sm-form ">
                        <input type="date" id="tanggal2" name="tanggal2" class="form-control form-control-sm" placeholder="tanggal2" v-model="vdata.tanggal2" >
                    </div>
                    <hr>
                    <button type="button" @click="process=1;getData()" class="btn btn-sm btn-dark  "><span class="typcn typcn-zoom-in-outline"></span> Cari</button>
              </div>
              <div class="col-6 text-center">
                  <select class='form-control' v-model="vdata.pilihan">
                    <option value="1">Semua Data</option>
                  </select>                    
                  <hr>
                  <button  class="btn btn-sm btn-dark  " @click="process=2;getData()"><span class="typcn typcn-zoom-out-outline"></span> Cari</button>
              </div>
            </div>
          <!-- END BUTTON PART -->
        </div>
      </div>
    <!-- TABLE PART -->
    <div v-if="datanya.length>0" class="p-5 rounded-lg  shadow">
      <div class="">
        <button type="button" @click="$router.push(`/admin/laporan/laporan`)" class="btn btn-sm btn-dark float-right mt-2"><span class="typcn typcn-document-add"></span> Cetak</button>
        <vue-json-excel :data="datanya">
          <button type="button" class="btn btn-sm btn-dark">
            <span class="typcn typcn-chart-area"></span> Excel 
          </button>
        </vue-json-excel>
      </div>
        <br>
      <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
      <p class="font-bold text-xl">Total Jumlah Harga : Rp. {{formatRupiah(masuks)}}</p>
    </div>
    <!-- END TABLE PART -->
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  layout:'app_shop_admin',
  data() {
    return {
      selected: false,
      vdata: {},
      btn: "tambah",
      datanya:[],
      masuks:0,
      process:1,
      type:1
    };
  },
  methods: {
      excel(){
          
      },
    getData(){
        let that=this;
        this.datanya=[]
        let sql;
        // ===============
        var process1=``
        var process2=``
        if(this.type=='1'){
          process1=`and tb1.type='1'`
          process2=`where tb1.type='1'`
        }else if(this.type=='2'){
          process1=`and tb1.type='2'`
          process2=`where tb1.type='2'`
        }
        // ===============
        if(this.process==1){
          sql=`select tb1.id as no,tb1.barcode,tb1.nama_produk,tb1.detail,tb1.stok,tb2.kategori,tb3.satuan,tb1.star1,tb1.star2,tb1.star3,tb1.star4,tb1.star5,tb1.harga_beli,tb1.diskon,tb1.harga from app_kasir_produk tb1 
              left join app_kasir_produk_kategori tb2 on tb2.id=tb1.kategori left join app_kasir_produk_satuan tb3 on tb3.id=tb1.satuan  
              WHERE tb1.createdAt >= '${this.vdata.tanggal1}' AND tb1.createdAt <= '${this.vdata.tanggal2}' ${process1}`;
        }else if(this.process==2){
          if(this.vdata.pilihan==1){
            sql=`select tb1.id as no,tb1.barcode,tb1.nama_produk,tb1.detail,tb1.stok,tb2.kategori,tb3.satuan,tb1.star1,tb1.star2,tb1.star3,tb1.star4,tb1.star5,tb1.harga_beli,tb1.diskon,tb1.harga
                from app_kasir_produk tb1
                left join app_kasir_produk_kategori tb2 on tb2.id=tb1.kategori left join app_kasir_produk_satuan tb3 on tb3.id=tb1.satuan ${process2}`
          }
        }
        console.log(sql)
      sdb.collection("app_kasir_stok_masuk",false).doc().select(sql).then(res => {
        this.datanya=res;
        this.masuks=res.reduce((e,n)=>{return e+(parseInt(n.harga))},0)
        let harga_beli=res.reduce((e,n)=>{return e+(parseInt(n.harga_beli))},0)
          this.datanya.forEach((e,i)=>{
            e.no=i+1;
            e.harga=that.formatRupiah(e.harga,'Rp. ')
            e.harga_beli=that.formatRupiah(e.harga_beli,'Rp. ')
            e.star=that.getStar(e)
          })
          this.datanya.push({harga_beli:this.formatRupiah(harga_beli,'Rp. '),harga:this.formatRupiah(this.masuks,'Rp. ')})
          this.datanya.forEach(e=>{
              delete e.star1
              delete e.star2
              delete e.star3
              delete e.star4
              delete e.star5
          })
        this.$store.state.data.report_masuk=res;
        this.$forceUpdate();
      });
    },
    ambil(data) {
      this.vdata = data;
      this.btn = "update";
      this.$forceUpdate();
    },
     format(date) {
      return this.$datefns.format(date, "DD MMMM YYYY");
    },
     getStar(item){
        let hasil;
        if(((5*parseInt(item.star5) + 4*parseInt(item.star4) + 3*parseInt(item.star3) + 2*parseInt(item.star2) + 1*parseInt(item.star1)) / (parseInt(item.star5)+parseInt(item.star4)+parseInt(item.star3)+parseInt(item.star2)+parseInt(item.star1))).toFixed(1)=='NaN'){
          hasil=0;
        }else{
          hasil=((5*parseInt(item.star5) + 4*parseInt(item.star4) + 3*parseInt(item.star3) + 2*parseInt(item.star2) + 1*parseInt(item.star1)) / (parseInt(item.star5)+parseInt(item.star4)+parseInt(item.star3)+parseInt(item.star2)+parseInt(item.star1))).toFixed(1)
        }
        return hasil
      },
    formatRupiah(angka, prefix){ // prefix disini taruh Rp. 
        angka=angka.toString();
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
        if(ribuan){
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    },
    submit() {
     this.getData();
    },
  },
  async mounted() {
  },
};
</script>